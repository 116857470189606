@keyframes animate-cells {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.box {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 48px;
  height: 48px;
}
.box .cell {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}
.box .cell:nth-child(1) {
  background-color: #6d2ac1;
  opacity: 0;
  animation: animate-cells 1.1s ease-in-out alternate infinite;
  animation-delay: 0.9s;
}
.box .cell:nth-child(2) {
  background-color: #176ba0;
  opacity: 0;
  animation: animate-cells 1.5s ease-in-out alternate infinite;
  animation-delay: 0.1s;
}
.box .cell:nth-child(3) {
  background-color: #c02323;
  opacity: 0;
  animation: animate-cells 1.3s ease-in-out alternate infinite;
  animation-delay: 1s;
}
.box .cell:nth-child(4) {
  background-color: #176ba0;
  opacity: 0;
  animation: animate-cells 1.1s ease-in-out alternate infinite;
  animation-delay: 0.9s;
}
.box .cell:nth-child(5) {
  background-color: #c02323;
  opacity: 0;
  animation: animate-cells 1.1s ease-in-out alternate infinite;
  animation-delay: 0.2s;
}
.box .cell:nth-child(6) {
  background-color: #facd38;
  opacity: 0;
  animation: animate-cells 1.3s ease-in-out alternate infinite;
  animation-delay: 0.6s;
}
.box .cell:nth-child(7) {
  background-color: #6d2ac1;
  opacity: 0;
  animation: animate-cells 1.1s ease-in-out alternate infinite;
  animation-delay: 0.2s;
}
.box .cell:nth-child(8) {
  background-color: #6d2ac1;
  opacity: 0;
  animation: animate-cells 1.4s ease-in-out alternate infinite;
  animation-delay: 0.9s;
}
.box .cell:nth-child(9) {
  background-color: #ef7e32;
  opacity: 0;
  animation: animate-cells 1.1s ease-in-out alternate infinite;
  animation-delay: 0.2s;
}
.box .cell:nth-child(10) {
  background-color: #c02323;
  opacity: 0;
  animation: animate-cells 1.3s ease-in-out alternate infinite;
  animation-delay: 0.4s;
}
.box .cell:nth-child(11) {
  background-color: #1ac9e6;
  opacity: 0;
  animation: animate-cells 1.3s ease-in-out alternate infinite;
  animation-delay: 0.9s;
}
.box .cell:nth-child(12) {
  background-color: #176ba0;
  opacity: 0;
  animation: animate-cells 1.2s ease-in-out alternate infinite;
  animation-delay: 0.8s;
}
.box .cell:nth-child(13) {
  background-color: #eb4cc2;
  opacity: 0;
  animation: animate-cells 1.2s ease-in-out alternate infinite;
  animation-delay: 0.4s;
}
.box .cell:nth-child(14) {
  background-color: #eb4cc2;
  opacity: 0;
  animation: animate-cells 1.3s ease-in-out alternate infinite;
  animation-delay: 1s;
}
.box .cell:nth-child(15) {
  background-color: #c02323;
  opacity: 0;
  animation: animate-cells 1.5s ease-in-out alternate infinite;
  animation-delay: 0.3s;
}
.box .cell:nth-child(16) {
  background-color: #6d2ac1;
  opacity: 0;
  animation: animate-cells 1.1s ease-in-out alternate infinite;
  animation-delay: 0.5s;
}
