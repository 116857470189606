:root,
[data-amplify-theme] {
  --amplify-colors-border-primary: #d0d0d0 !important;
}

body {
  margin: 0;
}

[data-amplify-authenticator] {
  padding: 1rem;
}
